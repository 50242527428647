import axios from 'axios';
import { Message } from 'element-ui';
import { jumpLogin } from '@/utils/auth';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const service = axios.create({
  baseURL: '/api',
  // 是否携带cookie
  withCredentials: true,
  timeout: 10000
});

// 请求前处理
service.interceptors.request.use(
  (config) => {
    NProgress.start();

    const token = window.localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  }
);

// 请求后处理
service.interceptors.response.use((response) => {
  NProgress.done();

  const res = response.data;
  if (res.code === 40003) {
    jumpLogin(1500);
    return res;
  }

  if (res.code !== 20000) {
    return Promise.reject(res || { msg: '返回错误！' });
  }

  return res;
}, (err) => {
  NProgress.done();

  if (!err.response) {
    Message.warning('服务端无响应!');
  }

  if (err && err.response) {
    let message = '请求错误';
    switch (err.response.status) {
      case 400:
        message = '请求错误';
        break;

      case 401:
        message = '未授权，请登录';
        jumpLogin(1500);
        break;

      case 403:
        message = '拒绝访问';
        break;

      case 404:
        message = `请求地址出错: ${err.response.config.url}`;
        jumpLogin(1500);
        break;

      case 408:
        message = '请求超时';
        break;

      case 500:
        message = '服务器内部错误';
        break;

      case 501:
        message = '服务未实现';
        break;

      case 502:
        message = '网关错误';
        break;

      case 503:
        message = '服务不可用';
        break;

      case 504:
        message = '网关超时';
        break;

      case 505:
        message = 'HTTP版本不受支持';
        break;

      default:
    }
    Message.warning(message);
  }

  return Promise.reject(err);
});

export default service;
