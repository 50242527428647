// 等待
export const sleep = (delay = 2000) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, delay);
});

// 时间格式化YYYY-mm-dd HH:MM
export const dateFormat = (fmt, dateStr) => {
  const date = new Date(dateStr);
  let ret;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp(`(${k})`).exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')));
    }
  }
  return fmt;
};

// 年龄
export const getAge = (str, config = {}) => {
  if (!str) return str;
  const r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})/);
  if (r == null) return null;

  const d = new Date(r[1], r[3] - 1, r[4]);
  let returnStr = '输入的日期格式错误！';
  let year = 0;
  let month = 0;
  let day = 0;

  if (d.getFullYear() === Number(r[1]) && (d.getMonth() + 1) === Number(r[3]) && d.getDate() === Number(r[4])) {
    const date = new Date();
    const yearNow = date.getFullYear();
    const monthNow = date.getMonth() + 1;
    const dayNow = date.getDate();

    const largeMonths = [1, 3, 5, 7, 8, 10, 12]; // 大月， 用于计算天，只在年月都为零时，天数有效
    const lastMonth = monthNow - 1 > 0 ? monthNow - 1 : 12; // 上一个月的月份
    let isLeapYear = false; // 是否是闰年
    let daysOFMonth = 0; // 当前日期的上一个月多少天

    if ((yearNow % 4 === 0 && yearNow % 100 !== 0) || yearNow % 400 === 0) { // 是否闰年， 用于计算天，只在年月都为零时，天数有效
      isLeapYear = true;
    }

    if (largeMonths.indexOf(lastMonth) > -1) {
      daysOFMonth = 31;
    } else if (lastMonth === 2) {
      if (isLeapYear) {
        daysOFMonth = 29;
      } else {
        daysOFMonth = 28;
      }
    } else {
      daysOFMonth = 30;
    }

    let Y = yearNow - parseInt(r[1]);
    let M = monthNow - parseInt(r[3]);
    let D = dayNow - parseInt(r[4]);
    day = D;
    if (D < 0) {
      D += daysOFMonth; // 借一个月
      M--;
    }
    if (M < 0) { // 借一年 12个月
      Y--;
      M += 12; //
    }
    year = Y;
    month = M;

    if (Y < 0) {
      returnStr = '出生日期有误！';
    } else if (Y === 0) {
      if (M === 0) {
        returnStr = `${D}天`;
      } else {
        returnStr = `${M}月${D}天`;
      }
    } else if (M === 0) {
      returnStr = `${Y}岁${D}天`;
    } else {
      returnStr = `${Y}岁${M}个月${D}天`;
    }
  }

  if (config.getYear) return year;

  if (config.getMonth) {
    const age = (month || 0) + (year || 0) * 12 + parseInt((day || 0) / 3) / 10;
    return age;
  }

  return returnStr;
};
