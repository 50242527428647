import router from '@/router/index';
import { sleep } from '@/utils/utils';

export const local = window.localStorage;

const USER_KEY = 'USER';

// 设置user
export const setUserInfo = (info) => {
  local.setItem('token', info.token);
  local.setItem(USER_KEY, JSON.stringify(info));
};

// 获取user
export const getUserInfo = () => {
  const userInfo = local.getItem(USER_KEY);
  return typeof userInfo === 'string' ? JSON.parse(userInfo) : {};
};

// 清除用户信息
export const removeUser = () => { local.removeItem(USER_KEY); };

// 清除系统信息
export const removeAll = () => { local.clear(); sessionStorage.clear(); };

// 跳到登陆页
export const jumpLogin = async (delay) => {
  if (typeof delay === 'number') {
    await sleep(delay);
  }
  const { current } = router.history;
  const redirect = JSON.stringify({
    path: current.path,
    query: current.query,
    params: current.params
  });
  removeAll();
  router.replace({
    name: 'login',
    query: { redirect, redirectType: 'object' }
  });
};

// 重定向
export const redirect = async (delay) => {
  if (typeof delay === 'number') {
    await sleep(delay);
  }
  const { current } = router.history;
  let obj = null;
  if (current.query.redirectType === 'object' && current.query?.redirect) {
    obj = JSON.parse(current.query?.redirect);
  } else if (current.query.redirect) {
    obj = { path: current.query.redirect };
  } else {
    obj = { path: '/' };
  }
  // router.replace(obj);
  router.replace('/');
};
